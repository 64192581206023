import {format} from 'date-fns'
function findNumberOfLevelsUntilNextBreak(nextBreak, currentBlindNumberIndex) {
  if (nextBreak) {
    return nextBreak.afterLevelNumber - currentBlindNumberIndex -  1
  } else {
    return 0
  }
}
export default function timeUntilNextBreakText(nextBreak, currentBlindNumber, levelLengthInMinutes) {
  if (nextBreak) {
    const levelsUntilNextBreak = findNumberOfLevelsUntilNextBreak(nextBreak, currentBlindNumber)
    const now = new Date()
    now.setMinutes(now.getMinutes() + ((levelsUntilNextBreak + 1) * levelLengthInMinutes))

    return format(now, 'h:mmaaa')
  }
  return 'N/A'
}
