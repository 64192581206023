import React from 'react'
import PropTypes from 'prop-types'
import Grid from '@mui/material/Unstable_Grid2'
import Countdown from '../countdown/Countdown'
import {useDispatch} from 'react-redux'
import {FINISH_BREAK} from '../../store/scheduleDuck'

Break.propTypes = {
  countdownCompleteCallback: PropTypes.func,
  clockIsRunning: PropTypes.bool,
  length: PropTypes.number,
  secondsLeftInCurrentSession: PropTypes.number,
}

function Break({length, clockIsRunning, countdownCompleteCallback, secondsLeftInCurrentSession}) {
  const dispatch = useDispatch()

  const onCountdownComplete = () => {
    dispatch({type: FINISH_BREAK})
    countdownCompleteCallback()
  }

  return (
    <Grid container justifyContent={'center'} alignItems={'center'}>
      <Grid xs={12}>
        <Countdown lengthInSeconds={length * 60}
          clockIsRunning={clockIsRunning}
          onCountdownComplete={onCountdownComplete}
          secondsLeftInCurrentSession={secondsLeftInCurrentSession}/>
      </Grid>
    </Grid>
  )
}

export default Break
