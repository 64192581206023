import {initializeApp} from 'firebase/app'
import {getAuth, setPersistence, signInWithPopup,
  GoogleAuthProvider, connectAuthEmulator, browserLocalPersistence,
  signOut as firebaseSignOut
} from 'firebase/auth'
import { collection, getFirestore, doc, getDoc, connectFirestoreEmulator, getDocs } from 'firebase/firestore'
import { getFunctions, httpsCallable, connectFunctionsEmulator } from 'firebase/functions'

export const GOOGLE_SIGN_IN = 'google'

const googleAuthProvider = new GoogleAuthProvider()
const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID
}
const firebaseApp = initializeApp(firebaseConfig)
const auth = getAuth(firebaseApp)
const firestore = getFirestore(firebaseApp)
const functions = getFunctions(firebaseApp)

if (process.env.REACT_APP_USE_EMULATOR === 'true') {
  connectAuthEmulator(auth, 'http://127.0.0.1:9099')
  connectFirestoreEmulator(firestore, '127.0.0.1', 8080)
  connectFunctionsEmulator(functions, '127.0.0.1', 5001)
}

const getUserId = () => auth?.currentUser?.uid

const getUserProfile = async () => {
  const userRef = doc(firestore, 'users', getUserId())
  const userSnapshot = await getDoc(userRef)
  if (userSnapshot.exists()) {
    return userSnapshot.data()
  }
}

export const signOut = () => {
  return firebaseSignOut(auth)
}

export const signIn = async providerMethod => {
  let provider
  switch (providerMethod) {
    case GOOGLE_SIGN_IN: {
      provider = googleAuthProvider
      break
    }
    default: {
      return
    }
  }
  await setPersistence(auth, browserLocalPersistence)
  try {
    await signInWithPopup(auth, provider)
    const userProfile = await getUserProfile()
    if (userProfile) {
      return userProfile
    } else {
      const createUser = httpsCallable(functions, 'createUser')
      return createUser({ userId: getUserId() })
    }
  } catch (error) {
    if (error?.code !== 'auth/popup-closed-by-user') {
      if (error?.message.includes('has not been invited')) {
        console.warn('User not invited')
      } else {
        console.error(error)
        console.log(`error.code: ${error?.code}`)
        console.log(`error.message: ${error.message}`)
      }
    }
  }
}

export const getSchedules = async () => {
  const querySnapshot = await getDocs(collection(firestore, `/users/${getUserId()}/schedules`))
  const schedules = []
  querySnapshot.forEach((doc) => {
    schedules.push(doc.data())
  })
  return schedules
}

export const updateSchedule = async schedule => {
  const updateScheduleCallable = httpsCallable(functions, 'updateSchedule')
  return updateScheduleCallable({schedule})
}

export const deleteScheduleByName = async scheduleName => {
  const deleteScheduleCallable = httpsCallable(functions, 'deleteSchedule')
  return deleteScheduleCallable({scheduleName})
}

export const createSession = async session => {
  const createSessionCallable = httpsCallable(functions, 'createSession')
  return createSessionCallable({session})
}

export const deleteSession = async sessionId => {
  const deleteSessionCallable = httpsCallable(functions, 'deleteSession')
  return deleteSessionCallable({sessionId})
}
