import React from 'react'
import PropTypes from 'prop-types'
import Grid from '@mui/material/Unstable_Grid2'
import Typography from '@mui/material/Typography'

Blind.propTypes = {
  blind: PropTypes.shape({
    smallBlind: PropTypes.number,
    bigBlind: PropTypes.number
  }).isRequired,
  isNextLevel: PropTypes.bool,
}
const fontSizeArray = ['3rem', '4rem', '6rem', '8rem', '10rem']
const nextLevelFontSizeArray = ['1rem', '1.5rem', '2rem', '2.5rem', '3rem']
function Blind({blind, isNextLevel}) {
  const fontSize = isNextLevel ? nextLevelFontSizeArray : fontSizeArray
  const styles = {fontSize, lineHeight: 1}
  if (isNextLevel) {
    styles.opacity = .5
  }

  return (
    <Grid container justifyContent={'center'} alignItems={'center'}>
      <Typography sx={styles} pr={1}>{isNextLevel ? 'Next Round:' : ''}</Typography>
      <Typography sx={styles}>{blind.smallBlind / 1000 >= 1 ? `${blind.smallBlind / 1000}K` : blind.smallBlind}</Typography>
      <Typography sx={styles} pr={1} pl={1}>/</Typography>
      <Typography sx={styles}>{blind.bigBlind / 1000 >= 1 ? `${blind.bigBlind / 1000}K` : blind.bigBlind}</Typography>
    </Grid>
  )}

export default Blind
