import React, {useState, useContext} from 'react'
import AppBar from '@mui/material/AppBar'
import Box from '@mui/material/Box'
import Divider from '@mui/material/Divider'
import Drawer from '@mui/material/Drawer'
import IconButton from '@mui/material/IconButton'
import List from '@mui/material/List'
import MenuIcon from '@mui/icons-material/Menu'
import Toolbar from '@mui/material/Toolbar'
import Typography from '@mui/material/Typography'
import {SignInButton, SignInNavItem, SignOutButton, SignOutNavItem} from './auth/Auth'
import {AuthContext} from './auth/AuthContext'
import Button from '@mui/material/Button'
import {useNavigate} from 'react-router-dom'
import NavItem from './NavItem'

const drawerWidth = 240
const appTitle = 'Fancy Timer'

const SignedInList = () => {
  const navigate = useNavigate()
  return (
    <List>
      <NavItem text={'Payouts'} onClick={() => navigate('/payouts')} />
      <NavItem text={'Schedules'} onClick={() => navigate('/schedules')} />
      {process.env.REACT_APP_ENABLE_SESSIONS === 'true' && <NavItem text={'Sessions'} onClick={() => navigate('/sessions')} />}
      <SignOutNavItem/>
    </List>
  )
}

function ResponsiveAppBar() {
  const [mobileOpen, setMobileOpen] = useState(false)
  const {currentUser} = useContext(AuthContext)
  const navigate = useNavigate()

  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState)
  }

  return (
    <>
      <AppBar component="nav">
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { sm: 'none' } }}
          >
            <MenuIcon />
          </IconButton>
          <Typography
            variant="h6"
            component="div"
            sx={{ flexGrow: 1, display: { xs: 'none', sm: 'block' } }}
          >
            {appTitle}
          </Typography>
          <Box sx={{ display: { xs: 'none', sm: 'block' } }}>

          </Box>
          {currentUser ?
            <Box sx={{ display: { xs: 'none', sm: 'block' } }}>
              <Button sx={{ color: '#fff' }} onClick={() => navigate('/payouts')}>
                Payouts
              </Button>
              <Button sx={{ color: '#fff' }} onClick={() => navigate('/schedules')}>
                Schedules
              </Button>
              {process.env.REACT_APP_ENABLE_SESSIONS === 'true' && <Button sx={{ color: '#fff' }} onClick={() => navigate('/sessions')}>
                Sessions
              </Button>}
              <SignOutButton/>
            </Box> :
            <Box sx={{ display: { xs: 'none', sm: 'block' } }}>
              <SignInButton/>
            </Box>
          }
        </Toolbar>
      </AppBar>
      <nav>
        <Drawer
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: 'block', sm: 'none' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
          }}
        >
          <Box onClick={handleDrawerToggle} sx={{ textAlign: 'center' }}>
            <Typography variant="h6" sx={{ my: 2 }}>
              {appTitle}
            </Typography>
            <Divider />
            {currentUser ? <SignedInList/> : <List><SignInNavItem/></List>}
          </Box>
        </Drawer>
      </nav>
    </>
  )
}

export default ResponsiveAppBar
