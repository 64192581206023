import React from 'react'
import PropTypes from 'prop-types'
import Countdown from '../countdown/Countdown'
import Typography from '@mui/material/Typography'

Level.propTypes = {
  countdownCompleteCallback: PropTypes.func,
  secondsLeftInCurrentSession: PropTypes.number,
  isCurrentLevel: PropTypes.bool,
  lastLevel: PropTypes.bool,
  clockIsRunning: PropTypes.bool,
  level: PropTypes.number,
  length: PropTypes.number, // TODO change to lengthInMinutes
  blind: PropTypes.shape({
    smallBlind: PropTypes.number,
    bigBlind: PropTypes.number,
    ante: PropTypes.number,
  }).isRequired
}

function Level({
  isCurrentLevel,
  length,
  lastLevel,
  clockIsRunning,
  countdownCompleteCallback,
  secondsLeftInCurrentSession
}) {
  return (
    <>
      {!lastLevel && isCurrentLevel &&
        <Countdown lengthInSeconds={length * 60}
          clockIsRunning={clockIsRunning}
          onCountdownComplete={countdownCompleteCallback}
          secondsLeftInCurrentSession={secondsLeftInCurrentSession}/>
      }
      {!isCurrentLevel &&
        <Typography sx={{lineHeight: 1, fontSize: ['1rem', '1.5rem', '2rem', '2.5rem', '3rem']}}>
          {length}:00
        </Typography>
      }
      {lastLevel &&
        <Typography sx={{lineHeight: 1, fontSize: ['1rem', '1.5rem', '2rem', '2.5rem', '3rem']}}>
          Sudden Death
        </Typography>
      }
    </>
  )
}

export default Level
