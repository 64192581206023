import React from 'react'
import PropTypes from 'prop-types'
import './chip.css'
import Grid from '@mui/material/Unstable_Grid2'
import antiCircle from '../../assets/antiCircle.png'

Chips.propTypes = {
  chips: PropTypes.arrayOf(PropTypes.shape({
    color: PropTypes.string.isRequired,
    value: PropTypes.number.isRequired,
    colorUp: PropTypes.bool,
  })).isRequired,
}

function Chips({chips}) {
  return (
    <Grid container justifyContent={'space-around'}>
      { chips.filter(chip => !chip.hide).map(chip => {
        return (
          <Grid key={`${chip.color}-${chip.value}`}
            m={.5}
            className={`pokerChip flat ${chip.color}`}
            style={{background: chip.color}}>
            <div className={`chipValue ${chip.color}`}>
              {chip.value / 1000 >= 1 ? `${chip.value / 1000}K` : chip.value}
            </div>
            { chip.colorUp &&
              <div className="antiCircle">
                <img src={antiCircle} alt={'no more'} width="100" height="100"/>
              </div>
            }
          </Grid>
        )
      })}
    </Grid>
  )
}

export default Chips
