
const roundToNearestFive = (value) => {
  const remainder = value % 5
  if (remainder === 0) return {value, remainder}
  if (remainder >= 2.5) return {value: value + (5 - remainder), remainder: -(5 - remainder)}
  if (remainder < 2.5) return {value: value - remainder, remainder: remainder}
}

export default {
  calculate: (numberOfPlayers, buyInAmount, bountyAmount = 0) => {
    if (!numberOfPlayers || numberOfPlayers < 2) {
      return null
    }
    const purse = numberOfPlayers * buyInAmount
    let payoutResults
    if (numberOfPlayers >= 3 && numberOfPlayers <= 10) {
      let leftover = 0
      const first = roundToNearestFive(purse * .5)
      leftover += first.remainder
      const second = roundToNearestFive(purse * .3)
      leftover += second.remainder
      const third = roundToNearestFive(purse * .2)
      leftover += third.remainder
      payoutResults = {totalPurse: purse, first: first.value, second: second.value, third: third.value + leftover}
    } else if (numberOfPlayers >= 11 && numberOfPlayers <= 20) {
      let leftover = 0
      const first = roundToNearestFive(purse * .5)
      leftover += first.remainder
      const second = roundToNearestFive(purse * .25)
      leftover += second.remainder
      const third = roundToNearestFive(purse * .15)
      leftover += third.remainder
      const fourth = roundToNearestFive(purse * .1)
      leftover += fourth.remainder
      payoutResults =  {totalPurse: purse, first: first.value, second: second.value, third: third.value, fourth: fourth.value + leftover}
    } else if (numberOfPlayers >= 21 && numberOfPlayers <= 30) {
      let leftover = 0
      const first = roundToNearestFive(purse * .4)
      leftover += first.remainder
      const second = roundToNearestFive(purse * .25)
      leftover += second.remainder
      const third = roundToNearestFive(purse * .20)
      leftover += third.remainder
      const fourth = roundToNearestFive(purse * .1)
      leftover += fourth.remainder
      const fifth = roundToNearestFive(purse * .05)
      leftover += fifth.remainder
      payoutResults =  {totalPurse: purse, first: first.value, second: second.value, third: third.value, fourth: fourth.value, fifth: fifth.value + leftover}
    }
    return {...payoutResults, totalCollection: purse + (bountyAmount * numberOfPlayers)}
  }
}
