import React, {useEffect, useState} from 'react'
import './countdown.css'
import PropTypes from 'prop-types'
import Typography from '@mui/material/Typography'
import {useDispatch} from 'react-redux'
import {CLOCK_TICK} from '../../store/scheduleDuck'

const NORMAL_COLOR_CLASS = 'normal'
const WARNING_COLOR_CLASS = 'warning'
const DANGER_COLOR_CLASS = 'danger'
const WARNING_PERCENTAGE = 0.3
const DANGER_PERCENTAGE = 0.1

const timeToMinutesSeconds = (timeInSeconds) => {
  const minutes = Math.floor(timeInSeconds / 60)
  const seconds = timeInSeconds - minutes * 60
  let duration = ''
  if (minutes >= 10) {
    duration += `${minutes}:`
  } else {
    duration += `0${minutes}:`
  }
  if (seconds >= 10) {
    duration += `${seconds}`
  } else {
    duration += `0${seconds}`
  }
  return duration
}

const determineColorClass = (time, danger, warning) => {
  if (time <= danger) {
    return DANGER_COLOR_CLASS
  } else if (time <= warning) {
    return WARNING_COLOR_CLASS
  }
  return NORMAL_COLOR_CLASS
}

Countdown.propTypes = {
  onCountdownComplete: PropTypes.func.isRequired,
  lengthInSeconds: PropTypes.number.isRequired,
  clockIsRunning: PropTypes.bool.isRequired,
  secondsLeftInCurrentSession: PropTypes.number.isRequired,
}

function Countdown({onCountdownComplete, lengthInSeconds, clockIsRunning, secondsLeftInCurrentSession}) {
  const dispatch = useDispatch()
  const dangerSeconds = lengthInSeconds * DANGER_PERCENTAGE
  const warningSeconds = lengthInSeconds * WARNING_PERCENTAGE

  const [color, setColor] = useState(NORMAL_COLOR_CLASS)
  const [countDownDisplay, setCountDownDisplay] = useState(timeToMinutesSeconds(lengthInSeconds))

  useEffect(() => {
    if (secondsLeftInCurrentSession) {
      setColor(determineColorClass(secondsLeftInCurrentSession, dangerSeconds, warningSeconds))
      setCountDownDisplay(timeToMinutesSeconds(secondsLeftInCurrentSession))
      let intervalId
      if (clockIsRunning) {
        intervalId = setTimeout(() => {
          dispatch({ type: CLOCK_TICK, payload: secondsLeftInCurrentSession - 1 })
        }, 1000)
      }

      return () => clearInterval(intervalId)
    } else {
      if (clockIsRunning) {
        setColor(NORMAL_COLOR_CLASS)
        setCountDownDisplay(timeToMinutesSeconds(lengthInSeconds))
        onCountdownComplete()
      }
    }
  }, [clockIsRunning, onCountdownComplete, lengthInSeconds, secondsLeftInCurrentSession])

  return (
    <Typography sx={{ lineHeight: .75, fontSize: ['10rem', '15rem', '20rem', '25rem', '30rem'] }} className={color}>
      {countDownDisplay}
    </Typography>
  )
}

export default Countdown
