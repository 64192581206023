import React, {useEffect, useState} from 'react'
import Grid from '@mui/material/Grid'
import {Paper} from '@mui/material'
import Button from '@mui/material/Button'

const SessionGrid = () => {
  const [savedSession, setSavedSession] = useState(localStorage.getItem('Session'))

  useEffect(() => {
    setSavedSession(localStorage.getItem('Session'))
  }, [])

  const handleCreate = () => {
    const session = 'asdf'
    localStorage.setItem('Session', session)
    setSavedSession(session)
  }

  const handleDelete = () => {
    localStorage.removeItem('Session')
    setSavedSession(null)
  }
  return (
    <Grid container component={Paper} p={1}>

      {savedSession ? <div>
        <p>{`Session: ${savedSession}`}</p>
        <Button variant="contained" color="secondary" onClick={handleDelete}>
            Delete Session
        </Button>
      </div>
        : <Button variant="contained" color="primary" onClick={handleCreate}>
          Create Session
        </Button>
      }
    </Grid>
  )
}

export default SessionGrid
