const removeTheGreatestCommonDenominator = (indexesToColorUp) => {
  return indexesToColorUp.slice(1, indexesToColorUp.length)
}

export function findChipsToColorUp(chips, blinds, currentBlindNumber) {
  if (!chips || !chips.length || !blinds || !blinds.length) {
    return []
  }
  const indexesToColorUp = []
  for (let chipIndex = chips.length - 1; chipIndex >= 0; chipIndex--) {
    const chip = chips[chipIndex]
    let allBlindsDivisibleByChip = true
    for (let blindIndex = currentBlindNumber; blindIndex < blinds.length; blindIndex++) {
      const blind = blinds[blindIndex]
      if (blind.smallBlind % chip.value !== 0) {
        allBlindsDivisibleByChip = false
        break
      }
    }
    if (allBlindsDivisibleByChip) {
      indexesToColorUp.push(chipIndex)
    }
  }
  return removeTheGreatestCommonDenominator(indexesToColorUp)
}

export function colorUp(chips, blinds, currentBlindNumber) {
  const chipsToColorUp = findChipsToColorUp(chips, blinds, currentBlindNumber)
  return chips.map((chip, index) => {
    if (chipsToColorUp.includes(index)) {
      if (chip.colorUp) {
        return { ...chip, hide: true }
      } else {
        return { ...chip, colorUp: true}
      }
    } else {
      return chip
    }
  })
}
