import React from 'react'
import {createRoot} from 'react-dom/client'
import {Provider as ReduxProvider} from 'react-redux'
import {StyledEngineProvider} from '@mui/material/styles'

import {configureStore} from './store/reduxStore'
import App from './App'
import reportWebVitals from './reportWebVitals'

const globalStore = configureStore()

const container = document.getElementById('root')
const app = <React.StrictMode>
  <ReduxProvider store={globalStore}>
    <StyledEngineProvider injectFirst>
      <App/>
    </StyledEngineProvider>
  </ReduxProvider>
</React.StrictMode>

const root = createRoot(container)
root.render(app)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals(console.log)
