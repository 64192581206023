import React, {useEffect, useState} from 'react'
import {createTheme, ThemeProvider} from '@mui/material/styles'
import CssBaseline from '@mui/material/CssBaseline'
import BlindsTimer from './timer/BlindsTimer'
import './app.css'
import Grid from '@mui/material/Unstable_Grid2'
import {Toolbar} from '@mui/material'
import ResponsiveAppBar from './ResponsiveAppBar'
import Box from '@mui/material/Box'
import {AuthContext} from './auth/AuthContext'
import {getAuth, onAuthStateChanged} from 'firebase/auth'
import {createBrowserRouter, RouterProvider} from 'react-router-dom'
import ErrorPage from './ErrorPage'
import PayoutsRoute from './payouts/PayoutsRoute'
import BlindsScheduleRoute from './schedule/BlindsScheduleRoute'
import BlindsSchedulesRoute from './schedule/BlindsSchedulesRoute'
import {getSchedules} from './firebase'
import {ScheduleContext} from './schedule/ScheduleContext'
import defaultSchedule from './blindsupervision-ed_2023-10-21-export.json'
import SessionsRoute from './session/SessionsRoute'

const theme = createTheme({
  palette: {
    type: 'dark',
    primary: {
      main: '#424242',
    },
    secondary: {
      main: '#b71c1c',
    },
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          background: '#424242',
        },
      },
    },
    MuiTypography: {
      display: 'none'
    }
  },
})

const HomeRoute = () => (
  <>
    <ResponsiveAppBar/>
    <Grid container id={'blindsSchedule'} direction={'column'} m={1}>
      <Toolbar/>
      <BlindsTimer/>
    </Grid>
  </>
)

const router = createBrowserRouter([
  {
    path: '/',
    errorElement: <ErrorPage/>,
    element: <HomeRoute/>
  },
  {
    path: '/payouts',
    errorElement: <ErrorPage/>,
    element: <PayoutsRoute/>
  },
  {
    path: '/schedules',
    errorElement: <ErrorPage/>,
    element: <BlindsSchedulesRoute/>
  },
  {
    path: '/schedules/:scheduleId',
    errorElement: <ErrorPage/>,
    element: <BlindsScheduleRoute/>
  },
  {
    path: '/sessions',
    errorElement: <ErrorPage/>,
    element: <SessionsRoute/>
  },
])
function App() {
  const firebaseAuth = getAuth()
  const [currentUser, setCurrentUser] = useState(null)
  const [schedules, setSchedules] = useState([])
  const updateSchedules = async() => {
    const userSchedules = await getSchedules()
    if (userSchedules?.length) {
      setSchedules(userSchedules)

    } else {
      setSchedules([defaultSchedule])
    }
  }
  useEffect(() => {
    const unsubscribe = onAuthStateChanged(firebaseAuth, user => {
      if (user) {
        setCurrentUser(user)
      } else {
        setCurrentUser(null)
      }
    })
    return () => unsubscribe()
  }, [])

  useEffect(() => {
    if (currentUser) {
      updateSchedules()
    }
  }, [currentUser])

  return (
    <ThemeProvider theme={theme}>
      <Box sx={{display: 'flex'}}>
        <CssBaseline/>
        <AuthContext.Provider value={{currentUser}}>
          <ScheduleContext.Provider value={{schedules, updateSchedules}}>
            <RouterProvider router={router}/>
          </ScheduleContext.Provider>
        </AuthContext.Provider>
      </Box>
    </ThemeProvider>
  )
}

export default App
