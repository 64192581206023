import React from 'react'
import PropTypes from 'prop-types'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemText from '@mui/material/ListItemText'
import ListItem from '@mui/material/ListItem'

NavItem.propTypes = {
  onClick: PropTypes.func.isRequired,
  text: PropTypes.string.isRequired,
}

function NavItem({text, onClick}) {
  return (
    <ListItem disablePadding onClick={onClick}>
      <ListItemButton sx={{textAlign: 'left'}}>
        <ListItemText primary={text}/>
      </ListItemButton>
    </ListItem>
  )
}

export default NavItem
