import React from 'react'
import PropTypes from 'prop-types'
import Blind from '../blind/Blind'
import Typography from '@mui/material/Typography'
import Grid from '@mui/material/Grid'

NextLevel.propTypes = {
  length: PropTypes.number, // TODO change to lengthInMinutes
  onBreak: PropTypes.bool,
  blind: PropTypes.shape({
    smallBlind: PropTypes.number,
    bigBlind: PropTypes.number,
    ante: PropTypes.number,
  })
}

function NextLevel({blind, onBreak, length}) {
  return (
    <Grid container justifyContent={'center'} alignItems={'center'}>
      { onBreak ?
        <Typography sx={{opacity: .5, lineHeight: 1, fontSize: ['1rem', '1.5rem', '2rem', '2.5rem', '3rem']}}>
          {length} minute break is next
        </Typography> :
        <Blind blind={blind} isNextLevel={true}/>
      }
    </Grid>
  )
}

export default NextLevel
