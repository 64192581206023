import React from 'react'
import {googleLogin} from './authDuck'
import {useDispatch} from 'react-redux'
import Button from '@mui/material/Button'
import {signOut as firebaseSignOut} from '../firebase'
import NavItem from '../NavItem'
import {useNavigate} from 'react-router-dom'

function SignInNavItem() {
  const dispatch = useDispatch()
  return <NavItem onClick={() => dispatch(googleLogin)} text={'Sign In'}/>
}

function SignInButton() {
  const dispatch = useDispatch()
  return (
    <Button sx={{ color: '#fff' }} onClick={() => dispatch(googleLogin)}>
      Sign In
    </Button>
  )
}

function SignOutNavItem() {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const signOut = () => {
    dispatch(firebaseSignOut)
    navigate('/')
  }
  return <NavItem onClick={signOut} text={'Sign Out'}/>
}

function SignOutButton() {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const signOut = () => {
    dispatch(firebaseSignOut)
    navigate('/')
  }
  return (
    <Button sx={{ color: '#fff' }} onClick={signOut}>
      Sign Out
    </Button>
  )
}

export {SignInNavItem, SignInButton, SignOutButton, SignOutNavItem}

