import PropTypes from 'prop-types'

export const ScheduleShape =
  PropTypes.shape({
    name: PropTypes.string.isRequired,
    blinds: PropTypes.arrayOf(PropTypes.shape({
      smallBlind: PropTypes.number,
      bigBlind: PropTypes.number,
      ante: PropTypes.number,
    })),
    breaks: PropTypes.arrayOf(PropTypes.shape({
      afterLevelNumber: PropTypes.number,
      length: PropTypes.number,
    })),
    blindsLength: PropTypes.number,
  })
