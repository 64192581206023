import {signIn} from '../firebase'

export const GOOGLE_SIGN_IN = 'google'

const login = async (signInMethod) => {
  return signIn(signInMethod)
}

export const googleLogin = () => {
  return login(GOOGLE_SIGN_IN)
}

export const DEFAULT_STATE = {
  user: null,
  userProfile: null,
  authenticating: false,
  creatingProfile: false,
  listeningToAuth: false,
  listeningToUserProfile: false,
  isLoading: true,
  hasAgreedToTerms: false,
}

export const AUTHENTICATION_START = '@auth/AUTHENTICATION_START'
export const AUTHENTICATION_COMPLETE = '@auth/AUTHENTICATION_COMPLETE'
export const CREATING_USER_PROFILE = '@auth/CREATING_USER_PROFILE'
export const USER_PROFILE_CREATED = '@auth/USER_PROFILE_CREATED'
export const USER_PROFILE_UPDATED = '@auth/USER_PROFILE_UPDATED'
export const START_LISTENING_TO_AUTH = '@auth/START_LISTENING_TO_AUTH'
export const START_LISTENING_TO_USER_PROFILE = '@auth/START_LISTENING_TO_USER_PROFILE'
export const NO_USER_PROFILE_EXISTS = '@auth/NO_USER_PROFILE_EXISTS'

export default (state = DEFAULT_STATE, { type, payload }) => {
  switch (type) {
    case AUTHENTICATION_START:
      return { ...state, authenticating: true, creatingProfile: true, isLoading: true }
    case START_LISTENING_TO_AUTH:
      return {
        ...state,
        listeningToAuth: true,
      }
    case START_LISTENING_TO_USER_PROFILE:
      return {
        ...state,
        listeningToUserProfile: true,
        isLoading: true,
      }
    case CREATING_USER_PROFILE:
      return { ...state, creatingProfile: true, isLoading: true }
    case USER_PROFILE_UPDATED:
      return {
        ...state,
        userProfile: payload,
        creatingProfile: false,
        hasAgreedToTerms: !!payload,
        isLoading: !!payload,
      }
    case USER_PROFILE_CREATED:
      return {
        ...state,
        userProfile: payload,
        creatingProfile: false,
        hasAgreedToTerms: true,
        isLoading: false,
      }
    case AUTHENTICATION_COMPLETE:
      return {
        ...state,
        authenticating: false,
        user: payload,
        isLoading: state.creatingProfile,
      }
    case NO_USER_PROFILE_EXISTS:
      return {
        ...state,
        hasAgreedToTerms: false,
        isLoading: false,
      }
    default:
      return state
  }
}
