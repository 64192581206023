import UIfx from 'uifx'
import alertAudio from './assets/Alarm-Positive.wav'

const alert = new UIfx(
  alertAudio,
  {
    volume: 1, // number between 0.0 ~ 1.0
    throttleMs: 100
  }
)

export default function () {
  alert.play()
}