import React, {useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import Grid from '@mui/material/Unstable_Grid2'
import Chips from './chips/Chips'
import Controls from './controls/Controls'
import Level from './level/Level'
import {START_NEXT_BLIND, START_BREAK, FINISH_BREAK, RESET_BLIND, RESET_BREAK} from '../store/scheduleDuck'
import Break from './break/Break'
import NextLevel from './level/NextLevel'
import isBreakNext from './break/isBreakNext'
import playAlert from '../audibleAlert'
import Blind from './blind/Blind'
import Typography from '@mui/material/Typography'
import NextBreak from './break/NextBreak'

function BlindsTimer() {
  const { blinds, chips, onBreak, currentBlindNumber, breaks, levelLength, nextBreakNumber, secondsLeftInCurrentSession } = useSelector((schedule) => schedule)
  const dispatch = useDispatch()
  const [isLastLevel, setIsLastLevel] = useState(currentBlindNumber + 1 >= blinds.length)
  const [breakIsNext, setBreakIsNext] = useState(false)
  const [currentBlind, setCurrentBlind] = useState(blinds[currentBlindNumber])
  const [nextBlind, setNextBlind] = useState(blinds[1])
  const [clockIsRunning, setClockIsRunning] = useState(false)

  const resetLevel = () => {
    if (onBreak) {
      dispatch({ type: RESET_BREAK, payload: breaks[nextBreakNumber]  })
    } else {
      dispatch({ type: RESET_BLIND })
    }
  }
  const skipLevel = () => {
    if (onBreak) {
      dispatch({ type: FINISH_BREAK })
    }
    if (breakIsNext) {
      dispatch({ type: START_BREAK, payload: breaks[nextBreakNumber] })
    } else {
      if (!isLastLevel) {
        dispatch({ type: START_NEXT_BLIND, payload: nextBlind })
      }
    }
  }
  const toggleTimer = () => {
    setClockIsRunning(!clockIsRunning)
  }
  const countdownCompleteCallback = () => {
    playAlert()
    if (breakIsNext) {
      dispatch({ type: START_BREAK, payload: breaks[nextBreakNumber] })
    } else {
      if (!isLastLevel) {
        dispatch({ type: START_NEXT_BLIND })
      }
    }
  }
  const renderBreak = () => (
    <Break countdownCompleteCallback={countdownCompleteCallback}
      clockIsRunning={clockIsRunning}
      length={breaks[nextBreakNumber].length}
      secondsLeftInCurrentSession={secondsLeftInCurrentSession}
    />
  )
  const renderCurrentLevel = () => (
    <Level blind={currentBlind}
      secondsLeftInCurrentSession={secondsLeftInCurrentSession}
      countdownCompleteCallback={countdownCompleteCallback}
      level={currentBlindNumber + 1}
      length={levelLength}
      lastLevel={isLastLevel}
      onBreak={onBreak}
      clockIsRunning={clockIsRunning}
      isCurrentLevel={true}/>
  )
  const renderNextLevel = () => (
    <NextLevel blind={nextBlind}
      level={currentBlindNumber + 2}
      length={levelLength}
    />
  )
  const renderNextLevelAsBreak = () => (
    <NextLevel
      onBreak={true}
      length={breaks[nextBreakNumber].length}
    />
  )

  useEffect(() => {
    setCurrentBlind(blinds[currentBlindNumber])
    setNextBlind(currentBlindNumber < blinds.length - 1 ? blinds[currentBlindNumber + 1] : null)
    setIsLastLevel(currentBlindNumber >= blinds.length - 1)
    const breakIsUpNext = !onBreak && isBreakNext(breaks, nextBreakNumber, currentBlindNumber)
    setBreakIsNext(breakIsUpNext)
  }, [blinds, currentBlindNumber, breaks, nextBreakNumber, onBreak])

  return (
    <Grid container>
      <Grid xs={12} container direction={'row'} justifyContent={{xs: 'space-between', sm: 'flex-start'}}>
        <Grid xs={6} sm={2}>
          {onBreak ?
            <Typography>Break:</Typography> :
            <Typography>Round: {currentBlindNumber + 1}</Typography>
          }
        </Grid>
        <Grid>
          <NextBreak/>
        </Grid>
      </Grid>
      <Grid xs={12} pt={1} pb={1} container justifyContent={'center'}>
        {onBreak ? renderBreak() : renderCurrentLevel()}
      </Grid>

      <Grid xs={12} pt={1} pb={1}>
        {onBreak ?
          <Typography sx={{fontSize: ['3rem', '4rem', '6rem', '8rem', '10rem'], lineHeight: 1, textAlign: 'center'}} pr={1} pl={1}>
            Break
          </Typography>
          : <Blind blind={currentBlind}/>
        }
      </Grid>

      <Grid xs={12} pt={1} pb={1} id={'blindsTimerBreakOrNextLevel'}>
        {breakIsNext && renderNextLevelAsBreak()}
        {!isLastLevel && !breakIsNext && renderNextLevel()}
      </Grid>
      { !isLastLevel &&
        <Grid xs={12} mt={2} mb={3} sx={{textAlign: 'center'}} id={'blindsTimerControls'}>
          <Controls onBreak={onBreak}
            controlText={clockIsRunning ? 'Pause' : 'Play'}
            resetLevel={resetLevel}
            skipLevel={skipLevel}
            toggleTimer={toggleTimer}/>
        </Grid>
      }
      <Grid xs={12} className={'chipsWrapper clearfix'} pt={2} pb={2} id={'blindsTimerChips'}>
        <Chips chips={chips}/>
      </Grid>
    </Grid>
  )
}

export default BlindsTimer
