import React, {useEffect, useState} from 'react'
import Typography from '@mui/material/Typography'
import {useSelector} from 'react-redux'
import timeUntilNextBreakText from './timeUntilNextBreak'

function NextBreak() {
  const { nextBreakNumber, breaks, currentBlindNumber, levelLength, onBreak } = useSelector(schedule => schedule)
  const [timeUntilNextBreak, setTimeUntilNextBreak] = useState('N/A')

  useEffect(() => {
    const hasAnotherBreak = nextBreakNumber < breaks.length
    if (hasAnotherBreak) {
      const nextBreak = onBreak ? breaks[nextBreakNumber + 1] : breaks[nextBreakNumber]
      if (onBreak) {
        setTimeUntilNextBreak(timeUntilNextBreakText(nextBreak, currentBlindNumber, levelLength))
      } else {
        setTimeUntilNextBreak(timeUntilNextBreakText(breaks[nextBreakNumber], currentBlindNumber, levelLength))
      }
    } else {
      setTimeUntilNextBreak('N/A')
    }
  }, [nextBreakNumber, currentBlindNumber, levelLength])

  return (
    <Typography component="div">Break @ ~{timeUntilNextBreak}</Typography>
  )
}

export default NextBreak
