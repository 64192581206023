import {colorUp} from '../timer/chips/colorUpCheck'

export const RESET_BLIND = 'blinds-schedule/schedule/resetBlind'
export const START_NEXT_BLIND = 'blinds-schedule/schedule/startNextBlind'
export const FINISH_BREAK = 'blinds-schedule/schedule/finishBreak'
export const RESET_BREAK = 'blinds-schedule/schedule/resetBreak'
export const START_BREAK = 'blinds-schedule/schedule/startBreak'
export const CLOCK_TICK = 'blinds-schedule/schedule/clockTick'
export const SWITCH_TO_NEW_SCHEDULE = 'blinds-schedule/schedule/switchToNewSchedule'

export const DEFAULT_STATE = {
  blinds: [],
  levelLength: 15,
  breaks: [],
  chips: [],
  currentBlindNumber: 0,
  nextBreakNumber: 0,
  onBreak: false,
  secondsLeftInCurrentSession: 15 * 60,
}

export default function reducer(state = DEFAULT_STATE, action = {}) {
  switch (action.type) {
    case SWITCH_TO_NEW_SCHEDULE:
      return {
        ...DEFAULT_STATE,
        ...action.payload,
        secondsLeftInCurrentSession: action.payload.levelLength * 60
      }
    case RESET_BLIND:
      return {
        ...state,
        currentBlindNumber: state.currentBlindNumber,
        onBreak: false,
        secondsLeftInCurrentSession: state.levelLength * 60
      }
    case RESET_BREAK:
      return {...state, secondsLeftInCurrentSession: action.payload.length * 60}
    case CLOCK_TICK:
      return {...state, secondsLeftInCurrentSession: action.payload}
    case FINISH_BREAK:
      return {
        ...state,
        nextBreakNumber: state.nextBreakNumber + 1,
        onBreak: false,
        secondsLeftInCurrentSession: state.levelLength * 60
      }
    case START_BREAK:
      return {...state, onBreak: true, secondsLeftInCurrentSession: action.payload.length * 60}
    case START_NEXT_BLIND:
      return {
        ...state,
        currentBlindNumber: state.currentBlindNumber + 1,
        onBreak: false,
        secondsLeftInCurrentSession: state.levelLength * 60,
        chips: colorUp(state.chips, state.blinds, state.currentBlindNumber + 1),
      }
    default:
      return state
  }
}
