import React, {useContext, useEffect, useState} from 'react'
import {ScheduleShape} from './schedule'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'
import {Field, Form} from 'react-final-form'
import RffTextFieldAdapter from '../form/RffTextFieldAdapter'
import Grid2 from '@mui/material/Unstable_Grid2'
import arrayMutators from 'final-form-arrays'
import {FieldArray} from 'react-final-form-arrays'
import Divider from '@mui/material/Divider'
import {updateSchedule} from '../firebase'
import {useNavigate} from 'react-router-dom'
import IconButton from '@mui/material/IconButton'
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline'
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline'
import {ScheduleContext} from './ScheduleContext'

BlindsSchedule.propTypes = {
  schedule: ScheduleShape
}

function BlindsSchedule({schedule}) {
  const navigate = useNavigate()
  const [tempSchedule, setTempSchedule] = useState(null)
  const [hasBeenUpdated, setHasBeenUpdated] = useState(false)
  const {updateSchedules} = useContext(ScheduleContext)

  useEffect(() => {
    if (!tempSchedule) {
      setTempSchedule({...schedule})
    }
  }, [schedule])

  async function onSubmit(values) {
    await updateSchedule(values)
    await updateSchedules()
    navigate('/schedules')
  }

  function insertNewBlind(index, values) {
    const blinds = [...values.blinds]
    blinds.splice(index, 0, {smallBlind: 0, bigBlind: 0, ante: 0})
    setTempSchedule({...values, blinds})
    setHasBeenUpdated(true)
  }

  function removeBlind(index, values) {
    const blinds = [...values.blinds]
    blinds.splice(index, 1)
    setTempSchedule({...values, blinds})
    setHasBeenUpdated(true)
  }

  function insertNewBreak(index, values) {
    const breaks = [...values.breaks]
    breaks.splice(index, 0, {afterLevelNumber: 0, length: 0})
    setTempSchedule({...values, breaks})
    setHasBeenUpdated(true)
  }

  function removeBreak(index, values) {
    const breaks = [...values.breaks]
    breaks.splice(index, 1)
    setTempSchedule({...values, breaks})
    setHasBeenUpdated(true)
  }

  function insertNewChip(index, values) {
    const chips = [...values.chips]
    chips.splice(index, 0, {color: '', value: 0})
    setTempSchedule({...values, chips})
    setHasBeenUpdated(true)
  }

  function removeChip(index, values) {
    const chips = [...values.chips]
    chips.splice(index, 1)
    setTempSchedule({...values, chips})
    setHasBeenUpdated(true)
  }

  return (
    <>
      <Typography variant={'h5'} component={'h3'} marginBottom={1}>Editing Schedule {schedule.name}</Typography>
      {tempSchedule &&
      <Form onSubmit={onSubmit}
        mutators={{...arrayMutators}}
        initialValues={tempSchedule}
        render={({
          handleSubmit,
          pristine,
          submitting,
          values
        }) => (
          <form onSubmit={handleSubmit} noValidate>
            <Grid2 container p={1} spacing={2}>
              <Grid2 xs={6}>
                <Field label={'Schedule Name'} name={'name'} required component={RffTextFieldAdapter} fullWidth/>
              </Grid2>
              <Grid2 xs={2}>
                <Field label={'Level Length'} name={'levelLength'} required component={RffTextFieldAdapter}
                  type={'number'}
                  parse={(value) => Number.parseInt(value)}/>
              </Grid2>
              <Grid2 container xs={12}>
                <Grid2 xs={12}><Divider/></Grid2>
                <Grid2 xs={12}>
                  <Typography variant={'h6'} component={'h4'}>Blinds</Typography>
                </Grid2>
                <FieldArray name="blinds">
                  {({fields}) =>
                    fields.map((blind, index) => (
                      <Grid2 container key={blind} xs={12} m={1} alignItems={'center'}>
                        <Grid2 xs={2}><Typography variant={'overline'}>Round #{index + 1}</Typography></Grid2>
                        <Grid2 container >
                          <Grid2 maxWidth={100} marginRight={1}>
                            <Field
                              name={`${blind}.smallBlind`}
                              component={RffTextFieldAdapter}
                              label="Small Blind"
                              size={'small'}
                              required
                              type={'number'}
                              parse={(value) => Number.parseInt(value)}
                            />
                          </Grid2>
                          <Grid2 maxWidth={100} marginRight={1}>
                            <Field
                              name={`${blind}.bigBlind`}
                              component={RffTextFieldAdapter}
                              label="Big Blind"
                              size={'small'}
                              required
                              type={'number'}
                              parse={(value) => Number.parseInt(value)}
                            />
                          </Grid2>
                          <Grid2 maxWidth={100}>
                            <Field
                              name={`${blind}.ante`}
                              component={RffTextFieldAdapter}
                              label="Ante"
                              size={'small'}
                              type={'number'}
                              parse={(value) => Number.parseInt(value)}
                            />
                          </Grid2>
                        </Grid2>
                        <Grid2 container>
                          <Grid2 marginRight={1}>
                            <IconButton aria-label="add" size="medium" color={'secondary'} onClick={() => insertNewBlind(index, values)}>
                              <AddCircleOutlineIcon fontSize="inherit" />
                            </IconButton>
                          </Grid2>
                          <Grid2>
                            <IconButton aria-label="subtract" size="medium" color={'primary'} onClick={() => removeBlind(index, values)}>
                              <RemoveCircleOutlineIcon fontSize="inherit" />
                            </IconButton>
                          </Grid2>
                        </Grid2>
                      </Grid2>
                    ))
                  }
                </FieldArray>
              </Grid2>
              <Grid2 container xs={12}>
                <Grid2 xs={12}><Divider/></Grid2>
                <Grid2 xs={12}>
                  <Typography variant={'h6'} component={'h4'}>Breaks</Typography>
                </Grid2>
                <FieldArray name="breaks">
                  {({fields}) =>
                    fields.map((scheduledBreak, index) => (
                      <Grid2 container key={scheduledBreak} xs={12} m={1} alignItems={'center'}>
                        <Grid2 xs={2}><Typography variant={'overline'}>Break #{index + 1}</Typography></Grid2>
                        <Grid2 maxWidth={120} marginRight={1}>
                          <Field
                            name={`${scheduledBreak}.afterLevelNumber`}
                            component={RffTextFieldAdapter}
                            label="After Level #"
                            size={'small'}
                            required
                            type={'number'}
                            parse={(value) => Number.parseInt(value)}
                          />
                        </Grid2>
                        <Grid2 maxWidth={120} marginRight={1}>
                          <Field
                            name={`${scheduledBreak}.length`}
                            component={RffTextFieldAdapter}
                            label="Length"
                            size={'small'}
                            required
                            type={'number'}
                            parse={(value) => Number.parseInt(value)}
                          />
                        </Grid2>
                        <Grid2 mx={2}>
                          <IconButton aria-label="add" size="medium" color={'secondary'} onClick={() => insertNewBreak(index, values)}>
                            <AddCircleOutlineIcon fontSize="inherit" />
                          </IconButton>
                        </Grid2>
                        <Grid2>
                          <IconButton aria-label="subtract" size="medium" color={'primary'} onClick={() => removeBreak(index, values)}>
                            <RemoveCircleOutlineIcon fontSize="inherit" />
                          </IconButton>
                        </Grid2>
                      </Grid2>
                    ))
                  }
                </FieldArray>
              </Grid2>
              <Grid2 container xs={12}>
                <Grid2 xs={12}><Divider/></Grid2>
                <Grid2 xs={12}>
                  <Typography variant={'h6'} component={'h4'}>Chips</Typography>
                </Grid2>
                <FieldArray name="chips">
                  {({fields}) =>
                    fields.map((chip, index) => (
                      <Grid2 container key={chip} xs={12} m={1} alignItems={'center'}>
                        <Grid2 maxWidth={120} marginRight={1}>
                          <Field
                            name={`${chip}.color`}
                            component={RffTextFieldAdapter}
                            label="Color"
                            size={'small'}
                            required
                          />
                        </Grid2>
                        <Grid2 maxWidth={120} marginRight={1}>
                          <Field
                            name={`${chip}.value`}
                            component={RffTextFieldAdapter}
                            label="Value"
                            size={'small'}
                            type={'number'}
                            parse={(value) => Number.parseInt(value)}
                            required
                          />
                        </Grid2>
                        <Grid2 mx={2}>
                          <IconButton aria-label="add" size="medium" color={'secondary'} onClick={() => insertNewChip(index, values)}>
                            <AddCircleOutlineIcon fontSize="inherit" />
                          </IconButton>
                        </Grid2>
                        <Grid2>
                          <IconButton aria-label="subtract" size="medium" color={'primary'} onClick={() => removeChip(index, values)}>
                            <RemoveCircleOutlineIcon fontSize="inherit" />
                          </IconButton>
                        </Grid2>
                      </Grid2>
                    ))
                  }
                </FieldArray>
              </Grid2>
              <Grid2 container xs={12}>
                <Grid2 xs={2} marginBottom={6} component={'footer'}>
                  <Button disabled={submitting} variant={'outlined'} onClick={() => navigate(-1)} fullWidth>
                    Cancel
                  </Button>
                </Grid2>
                <Grid2 xs={2} marginBottom={6} component={'footer'}>
                  <Button disabled={submitting || (pristine && !hasBeenUpdated)} variant={'contained'} type={'submit'} fullWidth>
                    Save
                  </Button>
                </Grid2>
              </Grid2>
            </Grid2>
          </form>
        )}/>}
    </>
  )
}

export default BlindsSchedule
