import React from 'react'
import PropTypes from 'prop-types'
import Button from '@mui/material/Button'
import Grid from '@mui/material/Unstable_Grid2'

Controls.propTypes = {
  controlText: PropTypes.string.isRequired,
  toggleTimer: PropTypes.func.isRequired,
  resetLevel: PropTypes.func.isRequired,
  skipLevel: PropTypes.func.isRequired,
}

function Controls({controlText, toggleTimer, resetLevel, skipLevel}) {
  return (
    <Grid container pl={2} pr={2} justifyContent={'space-between'}>
      <Grid xs={3}>
        <Button fullWidth={true} size="large" variant="contained" color="secondary" onClick={toggleTimer}>{controlText}</Button>
      </Grid>
      <Grid xs={3}>
        <Button fullWidth={true} size="large" variant="contained" color="secondary" onClick={resetLevel}>Reset</Button>
      </Grid>
      <Grid xs={3}>
        <Button fullWidth={true} size="large" variant="contained" color="secondary" onClick={skipLevel}>Skip</Button>
      </Grid>
    </Grid>
  )
}

export default Controls
