import ResponsiveAppBar from '../ResponsiveAppBar'
import Grid from '@mui/material/Unstable_Grid2'
import {FormControl, InputAdornment, InputLabel, OutlinedInput, Paper, TextField, Toolbar} from '@mui/material'
import React, {useEffect, useState} from 'react'
import Typography from '@mui/material/Typography'
import payoutCalculator from './payoutCalculator'
import PropTypes from 'prop-types'
import Divider from '@mui/material/Divider'

PayoutRow.propTypes = {
  title: PropTypes.string.isRequired,
  value: PropTypes.number.isRequired,
}
PayoutRow.defaultProps = {
  value: 0,
}

function PayoutRow({title, value}) {
  return (
    <Grid container flexDirection={'row'} justifyContent={'space-between'}>
      <Grid><Typography variant={'overline'}>{title}</Typography></Grid>
      <Grid><Typography variant={'overline'}>{value}</Typography></Grid>
    </Grid>
  )
}

function PayoutsRoute () {
  const [bountyAmount, setBountyAmount] = useState(0)
  const [buyInAmount, setBuyInAmount] = useState(0)
  const [numberOfPlayers, setNumberOfPlayers] = useState(0)
  const [payouts, setPayouts] = useState({})

  const handleBountyChange = (event) => {
    setBountyAmount(event.target.value)
  }
  const handleBuyInChange = (event) => {
    setBuyInAmount(event.target.value)
  }
  const handleNumberOfPlayersChange = (event) => {
    setNumberOfPlayers(event.target.value)
  }

  useEffect(() => {
    if (buyInAmount && numberOfPlayers) {
      setPayouts(payoutCalculator.calculate(numberOfPlayers, buyInAmount, bountyAmount))
    }
  }, [buyInAmount, numberOfPlayers, bountyAmount])

  return <>
    <ResponsiveAppBar/>
    <Grid container id={'payouts'} sx={{width: '100%'}} component={'form'} noValidate autoComplete={'off'}>
      <Toolbar/>
      <Grid container xs={12} component={Paper} p={1} >
        <Grid xs={12}>
          <Typography variant={'h5'} component={'h2'} gutterBottom>Payouts Calculator</Typography>
        </Grid>

        <Grid xs={12} sm={6} sx={{'& > :not(style)': { m: 1 }}} m={1}>
          <TextField id="number-of-players" margin={'normal'} required label="Number of Players" placeholder={'3 to 30 players'} type={'number'} value={numberOfPlayers}
            onChange={handleNumberOfPlayersChange}/>
          {/*TODO force buy in to be multiples of 5*/}
          <FormControl margin={'normal'} required>
            <InputLabel htmlFor="buy-in-amount">Buy In</InputLabel>
            <OutlinedInput
              id="buy-in-amount"
              startAdornment={<InputAdornment position="start">$</InputAdornment>}
              label="Buy In"
              type={'number'}
              value={buyInAmount}
              onChange={handleBuyInChange}
            />
          </FormControl>
          <FormControl margin={'normal'} required>
            <InputLabel htmlFor="bounty-amount">Bounty</InputLabel>
            <OutlinedInput
              id="bounty-amount"
              startAdornment={<InputAdornment position="start">$</InputAdornment>}
              label="Bounty"
              type={'number'}
              value={bountyAmount}
              onChange={handleBountyChange}
            />
          </FormControl>
        </Grid>
        <Grid xs={6} sm={2} m={1}>
          <Typography variant={'h6'} component={'h3'}>Payouts: </Typography>
          <PayoutRow title={'First'} value={payouts?.first}/>
          <PayoutRow title={'Second'} value={payouts?.second}/>
          <PayoutRow title={'Third'} value={payouts?.third}/>
          <PayoutRow title={'Fourth'} value={payouts?.fourth}/>
          <PayoutRow title={'Fifth'} value={payouts?.fifth}/>
          <Divider/>
          <PayoutRow title={'Total Payout'} value={payouts?.totalPurse}/>
          <Divider/>
          <Divider/>
          <PayoutRow title={'Total Collection'} value={payouts?.totalCollection}/>
        </Grid>
      </Grid>
    </Grid>
  </>
}

export default PayoutsRoute
