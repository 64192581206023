import React, {useContext, useEffect, useState} from 'react'
import ResponsiveAppBar from '../ResponsiveAppBar'
import Grid2 from '@mui/material/Unstable_Grid2'
import BlindsSchedule from './BlindsSchedule'
import {CircularProgress, Paper, Toolbar} from '@mui/material'
import {ScheduleContext} from './ScheduleContext'
import {useLocation} from 'react-router-dom'

function BlindsScheduleRoute() {
  const {schedules} = useContext(ScheduleContext)
  const [selectedSchedule, setSelectedSchedule] = useState(null)
  const location = useLocation()
  const pathParts = location.pathname.split('/')
  const scheduleName = decodeURIComponent(pathParts[pathParts.length - 1])

  useEffect(() => {
    if (!selectedSchedule) {
      setSelectedSchedule(schedules.find(schedule => schedule.name === scheduleName))
    }
  }, [])
  return (
    <>
      <ResponsiveAppBar/>
      <Grid2 container>
        <Toolbar/>
        <Grid2 xs={12} component={Paper} p={1}>
          {selectedSchedule ? <BlindsSchedule schedule={selectedSchedule}/> : <CircularProgress/>}
        </Grid2>
      </Grid2>
    </>
  )
}

export default BlindsScheduleRoute
