import React from 'react'
import PropTypes from 'prop-types'
import {TextField} from '@mui/material'

RffTextFieldAdapter.propTypes = {
  input: PropTypes.any,
  meta: PropTypes.any,
}

function RffTextFieldAdapter({input, meta, ...rest}) {
  return (
    <TextField
      {...input}
      {...rest}
      onChange={(event) => input.onChange(event.target.value)}
      error={meta.touched && meta.error}
      helperText={meta.touched ? meta.error : ''}
    />
  )
}

export default RffTextFieldAdapter
