import React, {useContext, useState} from 'react'
import Grid2 from '@mui/material/Unstable_Grid2'
import Button from '@mui/material/Button'
import {
  Backdrop,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Toolbar
} from '@mui/material'
import Typography from '@mui/material/Typography'
import {useNavigate} from 'react-router-dom'
import ResponsiveAppBar from '../ResponsiveAppBar'
import {ScheduleContext} from './ScheduleContext'
import {useDispatch} from 'react-redux'
import {SWITCH_TO_NEW_SCHEDULE} from '../store/scheduleDuck'
import {deleteScheduleByName} from '../firebase'

function BlindsSchedulesRoute() {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const {schedules, updateSchedules} = useContext(ScheduleContext)
  const [isLoadingSchedules, setIsLoadingSchedules] = useState(false)

  const startNewSchedule = (scheduleName) => {
    dispatch({type: SWITCH_TO_NEW_SCHEDULE, payload: schedules.find(schedule => schedule.name === scheduleName)})
    navigate('/')
  }

  const deleteSchedule = async scheduleName => {
    setIsLoadingSchedules(true)
    await deleteScheduleByName(scheduleName)
    await updateSchedules()
    setIsLoadingSchedules(false)
  }

  return (<>
    <ResponsiveAppBar/>
    <Grid2 container flexDirection={'column'} xs={12}>
      <Toolbar/>
      {/*TODO find a better way*/}
      <Backdrop label={'Loading'} open={isLoadingSchedules}/>
      <Grid2>
        <TableContainer component={Paper} >
          <Table aria-label="schedule table">
            <TableHead>
              <TableRow>
                <TableCell>Schedule Name</TableCell>
                <TableCell align={'right'}></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {!!schedules && schedules.map((row) => (
                <TableRow
                  key={row.name}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell component="th" scope="row"><Typography>{row.name}</Typography></TableCell>
                  <TableCell align={'right'}>
                    <Button variant="outlined" sx={{marginRight: 2}} onClick={() => startNewSchedule(row.name)}>Start</Button>
                    <Button variant="outlined" sx={{marginRight: 2}} onClick={() => navigate(`/schedules/${row.name}`)}>View / Edit</Button>
                    <Button variant="outlined" sx={{marginRight: 2}} onClick={() => deleteSchedule(row.name)}>Delete</Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid2>
      {!schedules?.length && <span>Add the default schedule to get started</span>}
    </Grid2>
  </>)
}

export default BlindsSchedulesRoute
