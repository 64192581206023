import React from 'react'
import ResponsiveAppBar from '../ResponsiveAppBar'
import Grid from '@mui/material/Grid'
import {Toolbar} from '@mui/material'
import SessionGrid from './SessionGrid'

const SessionsRoute = () => {
  return (
    <>
      <ResponsiveAppBar/>
      <Grid container id={'payouts'} sx={{width: '100%'}} component={'form'} noValidate autoComplete={'off'}>
        <Toolbar/>
        <SessionGrid/>
      </Grid>
    </>
  )
}

export default SessionsRoute
