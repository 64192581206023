import { createStore, applyMiddleware, compose } from 'redux'
import thunk from 'redux-thunk'
import schedule from '../blindsupervision-ed_2023-10-21-export.json'

import scheduleReducer from './scheduleDuck'

export const configureStore = () => {
  const preloadedState = {
    ...schedule,
    secondsLeftInCurrentSession: schedule.levelLength * 60,
    currentBlindNumber: 0,
    nextBreakNumber: 0,
    onBreak: false,
  }
  const middleware = [thunk]
  const enhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose

  return createStore(
    scheduleReducer,
    preloadedState,
    enhancers(applyMiddleware(...middleware))
  )
}
